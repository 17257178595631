<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-card>
        <b-row>
          <b-col cols="4">
            <h4 v-show="!filtre">{{ calismaGunu }} Tarihi Sonrası Rezervasyon Listesi</h4>
            <h4 v-show="filtre">{{ baslangic }} / {{ bitis }} Aralığı Rezervasyon Listesi</h4>
          </b-col>
          <b-col cols="8" style="justify-content: end; display: flex">
            <b-input-group class="float-right flat-date justify-content-end">
              <b-input-group-prepend>
                <flat-pickr v-model="baslangic" class="form-control"/>
              </b-input-group-prepend>
              <b-button variant="outline-primary" disabled class="disabledColor">
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr v-model="bitis" class="form-control mr-2"/>
              </b-input-group-append>
              <b-button @click="reset" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                        class="myButton mr-50"
              >
                <feather-icon icon="RefreshCcwIcon" class="mr-50"/>
                Sıfırla
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" v-print="'#printMe'">
                <feather-icon icon="PrinterIcon" class="mr-50"/>
                Listeyi Yazdır
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-row>
          <b-col sm="3" md="3" class="my-1">
            <b-input-group class="myGroup perPageClass">
              <b-input-group size="sm" prepend="Gösterilecek kayıt sayısı">
                <b-form-select @change="perPageChange($event)" id="per-page-select" v-model="perPage"
                               :options="pageOptions" size="sm"
                >
                </b-form-select>
              </b-input-group>
            </b-input-group>
          </b-col>
          <b-col md="2" class="my-1">
            <b-input-group size="sm">
              <b-input-group-prepend>
                <b-button variant="outline-primary" size="sm" disabled class="araButton">
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="filter" type="search"/>
            </b-input-group>
          </b-col>
          <b-col cols="12" id="printMe">
            <b-table empty-text="" class="bekleyenTahTable" small striped hover responsive :per-page="perPage"
                     :items="rezList" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                     :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                     @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                <a>{{ data.item.odaKodu }}</a>
              </template>
              <template #cell(rezervasyonNo)="data">
                <a>{{ data.item.rezervasyonNo }}</a>
              </template>
              <template #cell(adi)="data">
                <a>{{ data.item.adi }} {{ data.item.adi }}</a>
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
            </b-table>
          </b-col>
          <hr/>
          <b-row class="mt-3 alignItemsCenter">
            <b-col cols="6">
              <p class="mt-2 text-muted">Toplam {{ totalRows }} kayıt var</p>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end pr-5">
              <b-pagination @change="currentPageChange($event)" v-model="currentPage" :total-rows="totalRows"
                            :per-page="perPage" align="end" size="sm" class="my-0 pagination-info"
              />
            </b-col>
          </b-row>
        </b-row>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BContainer,
  BCol,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BInputGroupPrepend,
    flatPickr,
  },
  data() {
    return {
      spinner: true,
      toplamKayit: '',
      baslangic: '',
      bitis: '',
      filtre: false,
      perPage: 20,
      pageOptions: [5, 10, 15, 20, 25, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      fields: [
        {
          key: 'odaKodu',
          label: 'ODA KODU',
          sortable: true
        },
        {
          key: 'rezervasyonNo',
          label: 'REZERVASYON NO',
          sortable: true
        },
        {
          key: 'adi',
          label: 'KONUK İSMİ',
          sortable: true
        },
        {
          key: 'kayitTarih',
          label: 'GİRİŞ TARİHİ',
          sortable: true
        },
        {
          key: 'cikisTarih',
          label: 'ÇIKIŞ TARİHİ',
          sortable: true
        },
      ],
    }
  },
  watch: {
    baslangic: function (newVal, oldVal) {
      var today = new Date()
      var dd = String(today.getDate())
          .padStart(2, '0')
      var mm = String(today.getMonth() + 1)
          .padStart(2, '0')
      var yyyy = today.getFullYear()
      today = yyyy + '-' + mm + '-' + dd
      this.filtre = true
      this.$store.dispatch('rezervasyonList', {
        start: newVal,
        end: this.bitis || today,
        filtre: true,
        searchKey: this.filter,
        uzunluk: this.perPage,
      })
          .then(res => {
            this.totalRows = res.sayac
            this.toplamKayit = res.list.length
          })
      if (!this.bitis) {
        this.bitis = today
      }
    },
    bitis: function (newVal, oldVal) {
      var today = new Date()
      var dd = String(today.getDate())
          .padStart(2, '0')
      var mm = String(today.getMonth() + 1)
          .padStart(2, '0')
      var yyyy = today.getFullYear()
      today = yyyy + '-' + mm + '-' + dd
      this.filtre = true
      this.$store.dispatch('rezervasyonList', {
        start: this.baslangic || today,
        end: newVal,
        filtre: true,
        searchKey: this.filter,
        uzunluk: this.perPage,
      })
          .then(res => {
            this.totalRows = res.sayac
            this.toplamKayit = res.list.length
          })
      if (!this.baslangic) {
        this.baslangic = today
      }
    },
    filter(newVal) {
      if (this.baslangic && this.bitis) {
        this.$store.dispatch('rezervasyonList', {
          uzunluk: this.perPage,
          baslangic: 0,
          searchKey: newVal,
          start: this.baslangic,
          end: this.bitis,
          filtre: true,
        })
            .then(res => {
              this.totalRows = res.sayac
              this.toplamKayit = res.list.length
            })
      } else {
        this.islem = true
        this.$store.dispatch('rezervasyonList', {
          uzunluk: this.perPage,
          baslangic: 0,
          searchKey: newVal,
          filtre: null,
        })
            .then(res => {
              this.totalRows = res.sayac
              this.toplamKayit = res.list.length
            })
      }
    },
  },
  methods: {
    currentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = (newVal * this.perPage) - this.perPage
        let today = new Date()
        const yil = today.getFullYear()
        var gun = String(today.getDate())
            .padStart(2, '0')
        var ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = yil + '-' + ay + '-' + gun
        this.$store.dispatch('rezervasyonList', {
          uzunluk: this.perPage,
          baslangic: veri,
          searchKey: this.filter,
          start: this.baslangic || mevcutGun,
          end: this.bitis || mevcutGun,
          filtre: true,
        })
            .then(res => {
              this.totalRows = res.sayac
              this.toplamKayit = res.list.length
            })
      } else {
        const veri = (newVal * this.perPage) - this.perPage
        this.$store.dispatch('rezervasyonList', {
          uzunluk: this.perPage,
          baslangic: veri,
          searchKey: this.filter,
          filtre: null,
        })
            .then(res => {
              this.totalRows = res.sayac
              this.toplamKayit = res.list.length
            })
      }
    },
    perPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = (this.currentPage * newVal) - newVal
        this.$store.dispatch('rezervasyonList', {
          uzunluk: newVal,
          baslangic: veri,
          searchKey: this.filter,
          start: this.baslangic,
          end: this.bitis,
          filtre: true,
        })
            .then(res => {
              this.totalRows = res.sayac
              this.toplamKayit = res.list.length
            })
      } else {
        const veri = (this.currentPage * newVal) - newVal
        this.$store.dispatch('rezervasyonList', {
          uzunluk: newVal,
          baslangic: veri,
          searchKey: this.filter,
        })
            .then(res => {
              this.totalRows = res.sayac
              this.toplamKayit = res.list.length
            })
      }
    },
    reset() {
      this.filtre = false
      this.perPage = 20
      this.currentPage = 1
      this.$store.dispatch('rezervasyonList', {
        baslangic: 0,
        uzunluk: this.perPage,
        searchKey: '',
        filtre: null,
      })
          .then((res, position = 'bottom-right') => {
            this.totalRows = res.sayac
            this.toplamKayit = res.list.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
  },
  computed: {
    rezList() {
      if (this.$store.getters.rezervasyonListe) {
        return this.$store.getters.rezervasyonListe
      }
    },
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },
    calismaGunu() {
      return localStorage.getItem('raporCurrentDate')
    },
  },
  beforeDestroy() {
    this.$store.commit('clearRezervasyonList')
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    this.$store.dispatch('rezervasyonList', {
      baslangic: 0,
      uzunluk: this.perPage,
      searchKey: this.filter,
      start: this.baslangic,
      end: this.bitis,
    })
        .then((res, position = 'bottom-right') => {
          this.totalRows = res.sayac
          this.toplamKayit = res.list.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 10px !important;
  background-color: #0ea9d7;
  color: white;
}

.table th,
.table td {
  padding: 0 !important;
  width: 800px !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.araButton {
  border-color: #d8d6de !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  border-color: #d8d6de !important;
}

.perPageClass .input-group > .form-control:not(:first-child),
.perPageClass .input-group > .custom-select:not(:first-child) {
  max-width: 20% !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.alignItemsCenter {
  align-items: center !important;
  margin: 0 20px !important;
  min-width: 100% !important;
}

.table-responsive {
  padding: 0 10px !important;
}
</style>
<style>

.table.table-sm th,
.table.table-sm td {
  padding: 0rem 0rem !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
